<template>
  <div
    :class="{
      'mise-en-page-bureau': estEcranBureau,
      'mise-en-page-mobile': !estEcranBureau
    }"
  >
    <nav v-if="estEcranBureau">
      <div class="profile-corner">
        <div class="notification-topbar" ref="notif">
          <div
            class="notification-button"
            v-on:click="clickNotif"
            title="Notifications"
          >
            <font-awesome-icon
              class="icon"
              :icon="['far', 'bell']"
              v-if="!notification"
            />
            <font-awesome-icon class="icon" :icon="['fas', 'bell']" v-else />
            <span class="notif_number">{{ getNotificationNotVu.length }}</span>
          </div>
          <div class="popup-container" v-if="notification" id="dropdown">
            <div class="popup-header">
              <div>Notifications</div>
              <div @click="voirNotif">
                <router-link to="/notifications">Voir tout</router-link>
              </div>
            </div>
            <div class="popup-body">
              <span class="none" v-show="getNotification.length < 1"
                >Aucune notification</span
              >
              <div
                v-for="(notif, i) in getNotification"
                :key="i"
                class="notif"
                @click="closeNotif(notif, i)"
              >
                <router-link
                  :to="'/projects/' + notif.projet_id + '/activity'"
                  class="route-notif"
                  replace
                >
                  <div
                    class="content-notif"
                    :class="{
                      'gris-notif': notif.depuis == null
                    }"
                  >
                    <div class="ml-2 icon">
                      <font-awesome-icon
                        v-if="
                          notif.icon == 'note' || notif.activity_type == 'note'
                        "
                        class="icon-entity-note-comment"
                        icon="comment-alt"
                      />
                      <font-awesome-icon
                        v-else
                        class="icon-historique-activity-autre"
                        icon="hourglass-start"
                      />
                    </div>
                    <div class="content">
                      <div class="ml-3 titre">
                        {{ notif.activity_name }}
                      </div>
                      <div class="ml-3 text-color">
                        {{ notif.contenu.split(' , ')[0] }} <br />
                        <div class="group-hour-pointe">
                          <span class="hour-color" v-if="notif.time">
                            {{ notif.activity_date_start | timeFromNow(notif) }}
                          </span>
                          <span
                            class="pointe-notif"
                            v-if="notif.depuis == null || notif.seen == 0"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
                <hr class="line" v-if="i != getNotification.length - 1" />
              </div>
            </div>
          </div>
        </div>
        <Dropdown @notification="closeNotif">
          <template v-slot:header>
            <img src="../assets/img/head-silhouette.png" alt="" />
            <div v-if="currentUser" class="user-info">
              <span class="name">
                {{ currentUser.first_name }} {{ currentUser.last_name }}
              </span>
              <span v-if="currentUser.type == 'user.regie'" class="type">
                Régie
              </span>
              <span
                v-else-if="currentUser.type == 'user.commercial'"
                class="type"
              >
                Commercial
              </span>
              <span v-else class="type">
                {{ currentUser.details.title }}
                {{
                  currentUser.details.team &&
                  currentUser.details.team.data &&
                  currentUser.details.team.data.type == 'regie'
                    ? '(Régie)'
                    : ''
                }}
                {{
                  currentUser.details.team &&
                  currentUser.details.team.data &&
                  currentUser.details.team.data.type == 'filiale'
                    ? '(Filiale)'
                    : ''
                }}
              </span>
            </div>
            <div v-else class="user-info">
              template
              <span class="name">
                Profil
              </span>
              <span class="type">Inconnu</span>
            </div>
            <div class="caret"></div>
          </template>
          <template v-slot:options>
            <router-link
              v-if="(currentUser && currentUser.isSuper) || computedRegie"
              to="/setting"
            >
              Paramètres
            </router-link>
            <router-link to="/password">
              Modification de mot de passe
            </router-link>
            <a href="" @click.prevent="logout">Se déconnecter</a>
          </template>
        </Dropdown>
      </div>
      <div class="menu-corner">
        <div class="logo">
          <router-link to="/">
            <img src="../assets/img/logo-certigaia-blanc.png" alt="" />
          </router-link>
        </div>
        <Search />
        <div class="menu">
          <!-- <router-link v-if="computeReporting" to="/reporting">
          Reporting
        </router-link> -->
          <b-dropdown
            block
            class="custom-dropdown"
            :class="{ 'router-link-active': utilsRouterLinkActive }"
            toggle-class="custom-dropdown-button"
            no-caret
            v-if="computePlannings"
          >
            <template v-slot:button-content>
              Plannings
            </template>
            <b-dropdown-item
              v-if="!computePlanningTechnicienWithFlammeBleue"
              to="/planning-amo"
            >
              Planning AMO
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!computePlanningTechnicien"
              to="/planning-visiteur-tech"
            >
              Planning Visiteur technique
            </b-dropdown-item>
            <b-dropdown-item to="/planning-pose">
              Planning Pose
            </b-dropdown-item>
          </b-dropdown>
          <router-link v-if="currentUser" to="/projects">
            Projets
          </router-link>
          <b-dropdown
            block
            class="custom-dropdown"
            :class="{ 'router-link-active': stockRouterLinkActive }"
            toggle-class="custom-dropdown-button"
            no-caret
            v-if="computeStocks"
          >
            <template v-slot:button-content>
              Stocks
            </template>
            <b-dropdown-item to="/stock/pac-ballon">
              PAC Ballon
            </b-dropdown-item>
            <b-dropdown-item to="/stock/isolation">
              Isolation
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </nav>
    <nav v-else>
      <div class="profile-corner">
        <div class="bars-menu">
          <Dropdown @notification="closMeun('table')" ref="table">
            <template v-slot:header>
              <font-awesome-icon icon="table" class="icon-bars" />
            </template>
            <template v-slot:options>
              <div class="menu">
                <template v-if="computePlannings">
                  <div
                    @click.prevent.stop="btnPlanningsResponsive"
                    class="menu-bars-content"
                  >
                    Plannings
                    <font-awesome-icon class="ml-1" icon="caret-down" />
                  </div>
                  <div
                    v-if="btnPlannings"
                    :class="{ 'responsive-stocks': btnPlannings == true }"
                  >
                    <div
                      v-if="!computePlanningTechnicienWithFlammeBleue"
                      class="stocks-item"
                    >
                      <router-link to="/planning-amo">
                        Planning AMO
                      </router-link>
                    </div>
                    <div v-if="!computePlanningTechnicien" class="stocks-item">
                      <router-link to="/planning-visiteur-tech">
                        Planning Visiteur technique
                      </router-link>
                    </div>
                    <div class="stocks-item">
                      <router-link to="/planning-pose">
                        Planning Pose
                      </router-link>
                    </div>
                  </div>
                </template>

                <div class="menu-bars-content">
                  <router-link v-if="currentUser" to="/projects">
                    Projets
                  </router-link>
                </div>

                <template v-if="computeStocks">
                  <div
                    @click.prevent.stop="btnStockResponsive"
                    class="menu-bars-content"
                  >
                    Stocks
                    <font-awesome-icon class="ml-1" icon="caret-down" />
                  </div>
                  <div
                    v-if="btnStocks"
                    :class="{ 'responsive-stocks': btnStocks == true }"
                  >
                    <div class="stocks-item">
                      <router-link to="/stock/pac-ballon">
                        PAC Ballon
                      </router-link>
                    </div>
                    <div class="stocks-item">
                      <router-link to="/stock/isolation">
                        Isolation
                      </router-link>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </Dropdown>
        </div>
        <Search ref="search" :notPlaceholder="true" />
        <div class="notification-topbar" ref="notif">
          <div
            class="notification-button"
            v-on:click="clickNotifTous"
            title="Notifications"
          >
            <font-awesome-icon
              class="icon"
              :icon="['far', 'bell']"
              v-if="!notification"
            />
            <font-awesome-icon class="icon" :icon="['fas', 'bell']" v-else />
            <span class="notif_number">{{ getNotificationNotVu.length }}</span>
          </div>
        </div>
        <Dropdown
          @notification="closMeun('compte')"
          class="user-dropdown"
          ref="compte"
        >
          <template v-slot:header>
            <img src="../assets/img/head-silhouette.png" alt="" />
          </template>
          <template v-slot:options>
            <div v-if="currentUser" class="user-info">
              <span class="name">
                {{ currentUser.first_name }} {{ currentUser.last_name }}
              </span>
              <span v-if="currentUser.type == 'user.regie'" class="type">
                Régie0
              </span>
              <span
                v-else-if="currentUser.type == 'user.commercial'"
                class="type"
              >
                Commercial
              </span>
              <span v-else class="type">
                {{ currentUser.details.title }}
                {{
                  currentUser.details.team &&
                  currentUser.details.team.data &&
                  currentUser.details.team.data.type == 'regie'
                    ? '(Régie)'
                    : ''
                }}
                {{
                  currentUser.details.team &&
                  currentUser.details.team.data &&
                  currentUser.details.team.data.type == 'filiale'
                    ? '(Filiale)'
                    : ''
                }}
              </span>
            </div>
            <div v-else class="user-info">
              template
              <span class="name">
                Profil
              </span>
              <span class="type">Inconnu</span>
            </div>
            <router-link
              v-if="(currentUser && currentUser.isSuper) || computedRegie"
              to="/setting"
            >
              Paramètres
            </router-link>
            <router-link to="/password">
              Modification de mot de passe
            </router-link>
            <a href="" @click.prevent="logout">Se déconnecter</a>
          </template>
        </Dropdown>
      </div>
      <div class="menu-corner">
        <div class="logo">
          <router-link to="/">
            <img
              src="../assets/img/logo-certigaia-blanc.png"
              alt=""
              class="img"
            />
            <img
              src="../assets/img/logo-certigaia-blanc-icon.png"
              alt=""
              class="ecran-smart-phone"
            />
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Dropdown from '@/components/ui/Dropdown'
import Search from '@/components/Search'
import moment from 'moment'
export default {
  name: 'Topbar',
  components: {
    Dropdown,
    Search
  },
  data() {
    return {
      notification: false,
      estEcranBureau: true,
      btnStocks: false,
      btnPlannings: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'getNotification', 'getNotificationNotVu']),
    computeReportingRegie: function() {
      return (
        this.currentUser &&
        (this.currentUser.isSuper ||
          this.currentUser.type == 'user.commercial' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type === 'regie') ||
          this.currentUser.type == 'user.supervisor')
      )
    },
    computeOutils: function() {
      return (
        this.currentUser &&
        // this.currentUser.team_id != 425 && // filiale : ATELIER E-LEARNING
        this.currentUser.type != 'user.commercial' &&
        this.currentUser.type != 'user.final' &&
        (this.currentUser.type != 'user.admin' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type != 'regie'))
      )
    },
    computePlanningPoseurs: function() {
      return (
        this.currentUser &&
        // this.currentUser.team_id != 425 && // filiale : ATELIER E-LEARNING
        (this.currentUser.isSuper ||
          this.currentUser.type == 'user.tech.travaux' ||
          this.currentUser.type == 'user.agent' ||
          this.currentUser.type == 'user.resp.plan' ||
          this.currentUser.type == 'user.administration' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type === 'filiale'))
      )
    },
    computePlannings: function() {
      // le user admin type regie et user admin n'apppartient pas une equipe sont pas voir l'onglet plannings
      // user david@certigaia-group.com  ==> id ==61
      if (
        this.currentUser &&
        // AUCUN EQUIPE
        ((this.currentUser.type == 'user.admin' &&
          this.currentUser.details &&
          !this.currentUser.details.team &&
          this.currentUser.id != 61) ||
          // EQUIPE REGIE
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type === 'regie' &&
            this.currentUser.id != 61))
      ) {
        return false
      } else {
        return true
      }
    },
    computeStocks: function() {
      if (
        this.currentUser &&
        this.currentUser.details &&
        this.currentUser.details.stock == 1
      ) {
        return true
      } else {
        return false
      }
    },
    // si user tech travaux pas voir planning vt
    computePlanningTechnicien: function() {
      return this.currentUser && this.currentUser.type == 'user.tech.travaux'
    },
    //  si user de type tech travaux n'appartient pas equipe flamme bleue not voir le planning amo
    computePlanningTechnicienWithFlammeBleue: function() {
      if (
        this.currentUser &&
        this.currentUser.type == 'user.tech.travaux' &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        this.currentUser.details.team.data.id != 29
      ) {
        return true
      }
      return false
    },
    computeReporting: function() {
      return (
        this.currentUser &&
        (this.currentUser.isSuper ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type === 'filiale'))
      )
    },
    computedRegie: function() {
      return (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            (this.currentUser.details.team.data.type === 'regie' ||
              this.currentUser.details.team.data.type === 'installateur')))
      )
    },
    utilsRouterLinkActive: function() {
      return (
        this.$route.name === 'planning-amo' ||
        this.$route.name === 'planning-pose' ||
        this.$route.name === 'planning-visiteur-tech'
      )
    },
    stockRouterLinkActive: function() {
      return (
        this.$route.name === 'PacBallon' || this.$route.name === 'Isolation'
      )
    }
  },
  methods: {
    ...mapActions([
      'logout',
      'fetchListNotification',
      'fetchListNotificationNotVu'
    ]),
    btnPlanningsResponsive() {
      this.notification = false
      this.btnPlannings = !this.btnPlannings
    },
    btnStockResponsive() {
      this.notification = false
      this.btnStocks = !this.btnStocks
    },
    onResize() {
      this.estEcranBureau = window.innerWidth >= 827 // ajustez le point de rupture selon vos besoins
    },
    voirNotif() {
      this.notification = false
    },
    clickNotif() {
      this.notification = !this.notification
    },
    clickNotifTous() {
      this.notification = !this.notification
      this.$router.replace('/notifications').catch(() => {})
    },
    async closeNotif(notif) {
      if (notif) {
        const payload = {
          activity_id: notif.activity_id,
          activity_type: notif.icon
            ? notif.icon
            : notif.activity_type == 'Autre'
            ? 'activity'
            : notif.activity_type
        }
        const response = await this.fetchListNotificationNotVu(payload)
        if (response) {
          this.fetchListNotification(this.currentUser.id)
        }
      }
      this.notification = false
    },
    closMeun(e) {
      if (e == 'table' && this.$refs.compte.active) {
        this.$refs.compte.active = false
      } else if (e == 'compte' && this.$refs.table.active) {
        this.$refs.table.active = false
      }
      this.$refs.search.active = false
      this.notification = false
      this.btnPlannings = false
      this.btnStocks = false
    },
    close(e) {
      if (
        !this.$el.contains(e.target) ||
        (this.$el.contains(e.target) && !this.$refs['notif'].contains(e.target))
      ) {
        this.notification = false
      }
    }
  },
  filters: {
    timeFromNow: function(value, notif) {
      if (!value) return ''
      var seconds = notif.time.split(':')[2]
      var minutes = notif.time.split(':')[1]
      var hours = notif.time.split(':')[0]
      var result = ''
      var starttime = moment(value, 'YYYY-MM-DD hh:mm:ss')
      var diff = ''
      if (notif.depuis == '1') {
        result = moment(value)
          .add(seconds, 'seconds')
          .add(minutes, 'minutes')
          .add(hours, 'hours')
        diff = moment
          .duration(starttime.diff(result))
          .locale('fr')
          .humanize(true)
      } else {
        result = moment(value)
          .subtract(seconds, 'seconds')
          .subtract(hours, 'hours')
          .subtract(minutes, 'minutes')
        diff = moment
          .duration(starttime.diff(result))
          .locale('fr')
          .humanize(true)
      }

      return diff
    }
  },
  mounted() {
    document.addEventListener('click', this.close)
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 48px;
  z-index: 999;
  background: #404346;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.7);

  .menu-corner {
    display: flex;
    height: 48px;

    .logo {
      display: flex;
      font-size: 30px;
      font-weight: 900;
      align-items: center;
      padding: 0 20px;
      .ecran-smart-phone {
        display: none;
      }
      img {
        width: 150px;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      margin-left: 10px;
      a {
        display: block;
        overflow: hidden;
        font-weight: 400;
        padding: 18px;
        font-size: 15px !important;
        line-height: 11px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;
        color: #fff;

        &.router-link-active {
          background-color: #26292c;
        }
      }

      .custom-dropdown {
        height: 100%;
      }
    }
  }

  .profile-corner {
    float: right;
    display: flex;
    align-items: center;

    .notification-topbar {
      position: relative;

      .notification-button {
        position: relative;
        cursor: pointer;
        padding: 12px;
        text-decoration: none;
        color: #fff;
        font-size: 20px;
        border-radius: 45%;
        transition: all 0.3s ease;

        &:active {
          background-color: rgba(#fff, 0.1);
        }

        .notif_number {
          position: absolute;
          font-size: 12px;
          top: 4px;
          right: 4px;
          display: flex;
          align-items: center;
          background-color: #cc1016;
          text-align: center;
          z-index: 1;
          padding: 0 0.2rem;
          border-radius: 50%;
          height: 16px;
        }
      }

      .popup-container {
        border-radius: 3px;
        position: fixed;
        z-index: 900;
        right: 54px;
        top: 49px;
        font-size: 13px;
        width: 26%;
        max-width: 100%;
        will-change: transform;
        border: 1px solid rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
        background-color: #fff;

        .popup-header {
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 16px;
          width: 95%;
          margin: auto;
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
          padding: 8px 0;

          & a {
            text-decoration: none;
            color: #007bff;
            font-size: 14px;
            &:hover {
              color: #007bff;
              text-decoration: underline;
            }
          }
        }

        .popup-body::-webkit-scrollbar {
          height: 7px;
          width: 7px;
        }
        .popup-body::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #80808054;
          border-radius: 10px;
        }
        .popup-body::-webkit-scrollbar-thumb {
          background: #dcdee0;
          border-radius: 10px;
        }
        .popup-body::-webkit-scrollbar-thumb:hover {
          background: #888;
        }

        .popup-body {
          padding: 4px 6px;
          // text-align: center;
          overflow: auto;
          max-height: 370px;

          .none {
            font-size: 13px;
            color: #dc3545;
            margin-left: 77px;
          }

          .notif {
            display: block;
            text-decoration: none;
            margin-bottom: 4px;
            // padding: 8px 0;
            cursor: pointer;
            transition: all 0.3s ease;
            // padding: 10px 0px;
            .line {
              margin-top: 0px;
              margin-bottom: 0px;
            }
            .route-notif {
              &:hover {
                text-decoration: none;
              }
            }
            .router-link-active {
              &:hover {
                text-decoration: none;
              }
            }
            &:hover {
              background-color: rgb(45 171 226 / 33%);
              // padding: 10px 0px;
            }
            .content-notif {
              display: flex;
              align-items: center;
              margin-bottom: 7px;
              &.gris-notif {
                background-color: rgb(45 171 226 / 18%);
                padding: 10px 0;
              }

              .content {
                position: relative;
                .pointe-notif {
                  background: #2dabe2;
                  border-radius: 50%;
                  // position: absolute;
                  // right: 5px;
                  // top: 4px;
                  display: block;
                  width: 10px;
                  height: 10px;
                }
                .titre {
                  color: #404346;
                  font-weight: bold;
                  text-transform: capitalize;
                }
                .text-color {
                  color: black;
                  font-size: 11px;
                }
                .hour-color {
                  color: #007bff;
                  font-size: 11px;
                }
                .group-hour-pointe {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                }
              }
              .icon {
                border: 1px solid;
                padding: 6px 10px;
                border-radius: 20px;
                font-size: 14px;
                color: #6c757d;
              }
            }
          }
        }
      }
    }
    .dropdown-container {
      .dropdown {
        .user-info {
          .name {
            max-width: 180px;
            min-width: 50px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
          }
          .type {
            display: block;
            font-size: 12px;
            font-style: italic;
            max-width: 180px;
            min-width: 50px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.mise-en-page-mobile {
  /* styles spécifiques au mobile ici */
  .profile-corner {
    .bars-menu {
      border-radius: 28px;
      height: 36px;
      width: 36px;
      background-color: #a0a1a3;
      .icon-bars {
        color: #939496;
        position: absolute;
        left: -1px;
        font-size: 18px;
        top: 9px;
      }
      .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: -10px;
        .menu-bars-content {
          border: 1px solid #404346;
          width: 94%;
          color: white;
          padding: 12px;
          background-color: #404346;
          cursor: pointer;
          a {
            padding: 0px;
            &:hover {
              background-color: transparent;
            }
          }
          &:hover {
            background-color: #000000cc;
          }
        }
        .responsive-stocks {
          width: 94%;
          .stocks-item {
            .router-link-active {
              background-color: #dbdbdb;
              color: #343a40;
            }
            a {
              &:hover {
                background-color: #e8e8e8;
              }
            }
          }
        }
      }
    }
    .notification-topbar {
      .popup-container {
        right: 0px;
        width: 45%;
        max-width: 100%;
      }
    }
    .user-info {
      padding: 10px;
      text-align: center;
      border-bottom: 1px solid #c1c1c1;
    }
    .user-dropdown {
      a {
        &:hover {
          background-color: #ebebeb;
        }
      }
    }
  }

  .menu-corner {
    .logo {
      background-color: #404346;
      z-index: 1;

      .ecran-smart-phone {
        display: none;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .profile-corner {
      .bars-menu {
        border-radius: 28px;
        height: 36px;
        width: 36px;
        background-color: #a0a1a3;
        .icon-bars {
          color: #939496;
          position: absolute;
          left: -1px;
          font-size: 18px;
          top: 9px;
        }
        .menu {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: -10px;
          .menu-bars-content {
            border: 1px solid #404346;
            width: 94%;
            color: white;
            padding: 12px;
            background-color: #404346;
            cursor: pointer;
            a {
              padding: 0px;
              &:hover {
                background-color: transparent;
              }
            }
            &:hover {
              background-color: #000000cc;
            }
          }
          .responsive-stocks {
            width: 94%;
            .stocks-item {
              .router-link-active {
                background-color: #dbdbdb;
                color: #343a40;
              }
              a {
                &:hover {
                  background-color: #e8e8e8;
                }
              }
            }
          }
        }
      }
      .notification-topbar {
        .popup-container {
          right: 0px;
          width: 55%;
          max-width: 100%;
        }
      }
      .user-info {
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #c1c1c1;
      }
      .user-dropdown {
        .options {
          &.active {
            width: 100%;
            background-color: #404346;
            text-align: center;
            color: white;
          }
        }
        a {
          &:hover {
            background-color: #ebebeb;
          }
        }
      }
    }
    .menu-corner {
      .logo {
        background-color: #404346;
        z-index: 1;
        display: flex;
        font-size: 30px;
        font-weight: 900;
        align-items: center;
        padding: 0px 3px;
        margin-left: 0px;

        .img {
          display: none;
        }
        .ecran-smart-phone {
          display: block;
          width: 39px;
        }
      }
    }
  }
  @media screen and (max-width: 280px) {
    .fast-search {
      .search-input {
        width: 83px;
      }
    }
  }
}
</style>

<style lang="scss">
.mise-en-page-bureau {
  /* styles spécifiques au bureau ici */
  .custom-dropdown {
    .custom-dropdown-button {
      height: 100%;
      background-color: transparent;
      border: none;
      &:focus,
      &:hover,
      &:active {
        box-shadow: none;
        background-color: transparent;
        border: none;
      }
      &.btn-secondary:not(:disabled):not(.disabled):active {
        background-color: transparent;
      }
    }

    .btn-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-secondary:not(:disabled):not(.disabled).active:focus,
    & > .btn-secondary.dropdown-toggle {
      box-shadow: none;
      background-color: transparent;
      border: none;
    }

    &.router-link-active {
      background-color: #26292c;
    }

    .dropdown-menu {
      margin: 0;
      border-radius: 0;
      .dropdown-item {
        &.router-link-active {
          background-color: #404346;
          color: #fff;
        }
        &:active {
          background-color: #40434665;
        }
      }
    }
  }
}

.mise-en-page-mobile {
  /* styles spécifiques au mobile ici */
  .profile-corner {
    .bars-menu {
      .dropdown-container {
        .dropdown {
          &.active {
            border-radius: 28px;
            height: 36px;
            width: 36px;
            .icon-bars {
              position: absolute;
              top: 10px;
              left: 9px;
            }
          }
        }
        .options {
          width: 104%;
          right: -12px;
          &.active {
            .menu {
              .custom-dropdown {
                width: 100%;
                button {
                  background-color: transparent;
                  color: #212529;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
//
@media screen and (max-width: 827px) {
  nav {
    // height: 180px;
    width: 100%;
    max-width: 100%;
    box-shadow: unset;
    .profile-corner {
      .notification-topbar {
        display: block;
      }
      .dropdown-container {
        .dropdown {
          .user-info {
            display: none;
          }
          .caret {
            margin-left: 3px;
          }
        }
        &.active {
          .user-info {
            display: none;
          }
        }
        .options {
          &.active {
            a {
              &:hover {
                background-color: #000000cc;
              }
            }
            .user-profil {
              display: block;
              .user-info {
                text-align: center;
              }
              .line-hr {
                margin-top: 6px;
                margin-bottom: 2px;
              }
            }
          }
        }
        .dropdown-notif {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .icon {
            font-size: 20px;
          }
          .notif_number {
            top: 73px;
            right: 2px;
            border: 1px solid;
            border-radius: 15px;
            padding: 0px 5px;
            color: white;
            position: absolute;
            background-color: #db0f0f;
          }
        }
      }
    }
    .menu-corner {
      .fast-search {
        display: none;
      }
      .menu {
        display: none;
        margin-left: 0px;
      }
      .menu-bars {
        display: block;
        overflow: hidden;
        font-weight: 400;
        padding: 5px;
        font-size: 15px !important;
        line-height: 11px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        box-shadow: unset;
        background-color: #404346;
        border: 0px;
        border-radius: 0px;
        .btn-bars {
          background-color: #404346;
          border: 1px solid #cccccc;
          border-radius: 0px;
          float: right;
          &:hover {
            background-color: #212529;
            border: 1px solid #cccccc;
            border-radius: 0px;
            float: right;
          }
        }
      }
      .menu-bars-responsive {
        display: block;
        background-color: #404346;
        height: 422%;
        width: 26%;
        left: 114px;
        top: 47px;
        position: absolute;
        text-align: center;
        width: 55%;

        &.height-menu {
          height: 700%;
        }
        .fast-search {
          display: block;
        }

        .menu {
          display: block;
          .menu-bars-content {
            a {
              display: initial;
              &.router-link-active {
                background-color: transparent;
              }
            }
            cursor: pointer;
            display: block;
            overflow: hidden;
            font-weight: 400;
            padding: 18px;
            font-size: 15px !important;
            line-height: 11px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: none;
            color: #fff;
            &:hover,
            &:focus,
            &:active {
              background-color: #26292c;
            }
          }
          .responsive-stocks {
            background-color: #f9f9f9;
            margin-top: -5px;
            .stocks-item {
              &:hover {
                background-color: #dee2e6;
              }
            }
            a {
              color: #343a40;
              &.router-link-active {
                background-color: #dee2e6;
              }
            }
          }
        }
      }
    }
  }
}
</style>
