<template>
  <router-link :to="getLink" class="result-container">
    <span class="result-icon">
      <font-awesome-icon icon="file" />
    </span>

    <strong>
      <text-highlight
        :queries="getQueries"
        highlightClass="custom-mark-highlight"
      >
        {{ data.contact_full_name }}
      </text-highlight>
    </strong>
    <small>
      <text-highlight
        :queries="getQueries"
        highlightClass="custom-mark-highlight"
      >
        {{ data.contact_full_name ? data.contact_full_name : '' }}({{
          data.phone_number
        }})
        <template
          v-if="data.contact && data.contact.phone && data.contact.phone.number"
        >
          ({{ data.contact.phone.number }})
        </template>
      </text-highlight>
    </small>
    <span class="result-status" :class="data.sub_stage_status">
      {{ getStatus }}
    </span>
  </router-link>
</template>

<script>
import TextHighlight from 'vue-text-highlight'
export default {
  name: 'search-result',
  components: {
    TextHighlight
  },
  props: {
    data: {
      required: true
    },
    search: {
      require: false
    }
  },
  computed: {
    getLink: function() {
      return `/projects/${this.data.id}`
    },
    getStatus: function() {
      switch (this.data.sub_stage_status) {
        case 'Open':
        case 'open':
          return 'Ouvert'
        case 'Lost':
        case 'lost':
          return 'Perdu'
        case 'Won':
        case 'won':
          return 'Conclu'
      }
      return null
    },
    getQueries: function() {
      if (this.search) {
        const queries = this.search
          .split(/[., -]/)
          .filter(item => item && item.length > 1)
        const cleanSearch = this.search.replace(/[., -]/g, '')
        return [this.search, cleanSearch, ...queries]
      }
      return []
    }
  }
}
</script>

<style lang="scss">
.result-container {
  display: block;
  position: relative;
  padding: 4px 10px 4px 35px;
  color: #5e5e5e;

  .result-icon {
    position: absolute;
    left: 10px;
    top: 6px;
    font-size: 18px;
  }
  strong {
    display: block;
    padding: 3px 0;
  }
  small {
    display: block;
    padding: 2px 0;
  }

  .result-status {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 10px;
    color: #fff;
    border-radius: 10px;
    padding: 0 5px;
    &.Open,
    &.open {
      background: #2dabe2;
    }
    &.Won,
    &.won {
      background: #28a745;
    }
    &.Lost,
    &.lost {
      background: #ff6961;
    }
  }

  &:hover,
  &:active {
    background-color: #ebebeb;
    color: #5e5e5e;
    text-decoration: unset;
  }
}

.custom-mark-highlight {
  background: #ffc1073d !important;
  color: #26292c !important;
}
</style>
