<template>
  <div
    v-click-outside="close"
    :class="{
      active: active,
      open: search && active && getSearchReasult,
      'mise-en-page-bureau': estEcranBureau,
      'mise-en-page-mobile': !estEcranBureau
    }"
    class="fast-search"
  >
    <Spinner v-if="getSearchLoading" class="spinner" />
    <font-awesome-icon v-else class="icon" icon="search" />
    <input
      v-model="search"
      @focus="active = true"
      class="search-input"
      type="text"
      :placeholder="notPlaceholder ? '' : 'Chercher'"
    />
    <div v-if="search && active && getSearchReasult" class="global-search">
      <b-tabs class="global-search-tabs" :style="computedStyleWidth" lazy>
        <!-- <b-tab no-body title="Tous" title-link-class="global-search-tab-title"> -->
        <b-tab
          no-body
          title="Projets"
          title-link-class="global-search-tab-title"
        >
          <p v-if="!getSearchReasult.length" class="no-result">
            Aucun résultat pour "{{ search }}"
          </p>
          <template v-else>
            <ul class="search-results">
              <li
                @click="close"
                v-for="(item, index) in getSearchReasult"
                :key="`all-${index}-${item.id}`"
              >
                <SearchResult :data="item" @click="close" :search="search" />
              </li>
            </ul>
          </template>
        </b-tab>
        <!-- <b-tab
          no-body
          title="Projets"
          title-link-class="global-search-tab-title"
        >
          <p v-if="!getProjects.length" class="no-result">
            Aucun résultat pour "{{ search }}"
          </p>
          <template v-else>
            <ul class="search-results">
              <li v-for="item in getProjects" :key="'project-' + item.id">
                <SearchResult :data="item" @click="close" />
              </li>
            </ul>
          </template>
        </b-tab>
        <b-tab
          no-body
          title="Contacts"
          title-link-class="global-search-tab-title"
        >
          <p v-if="!getContact.length" class="no-result">
            Aucun résultat pour "{{ search }}"
          </p>
          <template v-else>
            <ul class="search-results">
              <li v-for="item in getContact" :key="'contact-' + item.id">
                <SearchResult :data="item" @click="close" />
              </li>
            </ul>
          </template>
        </b-tab>
        <b-tab
          no-body
          title="Fichiers"
          title-link-class="global-search-tab-title"
        >
          <p v-if="!getFiles.length" class="no-result">
            Aucun résultat pour "{{ search }}"
          </p>
          <template v-else>
            <ul class="search-results">
              <li v-for="item in getFiles" :key="'file-' + item.id">
                <SearchResult :data="item" @click="close" />
              </li>
            </ul>
          </template>
        </b-tab> -->
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import SearchResult from './SearchResult'

export default {
  components: {
    SearchResult,
    Spinner: () => import('@/components/ui/Spinner.vue')
  },
  props: { notPlaceholder: { required: false } },
  data() {
    return {
      active: false,
      search: '',
      estEcranBureau: true
    }
  },
  methods: {
    ...mapActions(['globalSearch']),
    close() {
      this.active = false
    },
    onResize() {
      this.estEcranBureau = window.innerWidth >= 827 // ajustez le point de rupture selon vos besoins
    }
  },
  computed: {
    ...mapGetters(['getSearchReasult', 'getSearchLoading']),
    computedStyleWidth: function() {
      if (window.innerWidth < 500) {
        return {
          width: window.innerWidth - 10 + 'px'
        }
      }
      return {}
    },
    getProjects: function() {
      if (this.getSearchReasult) {
        return this.getSearchReasult.filter(item => item.type == 'projet')
      }
      return []
    },
    getContact: function() {
      if (this.getSearchReasult) {
        return this.getSearchReasult.filter(item => item.type == 'contact')
      }
      return []
    },
    getFiles: function() {
      if (this.getSearchReasult) {
        return this.getSearchReasult.filter(item => item.type == 'file')
      }
      return []
    }
  },
  watch: {
    search: debounce(function(query) {
      if (query && query.length > 1) {
        this.globalSearch({
          conditions: [
            {
              glue: 'or',
              conditions: [
                {
                  object: 'project',
                  field_key: 'contact_full_name',
                  operator: '=',
                  value: query
                },
                {
                  object: 'project',
                  field_key: 'phones',
                  operator: '=',
                  value: query
                },
                {
                  object: 'project',
                  field_key: 'num_maison',
                  operator: '=',
                  value: query
                }
              ]
            }
          ]
        })
      }
    }, 400)
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
.fast-search {
  position: relative;
  padding: 6px;
  color: #26292c;

  .icon {
    position: absolute;
    font-size: 18px;
    top: 15px;
    left: 14px;
  }

  .spinner {
    position: absolute;
    top: 14px;
    left: 12px;
    width: 20px;
  }

  .search-input {
    height: 36px;
    width: 160px;
    padding: 3px 3px 3px 33px;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: none;
    border: none;

    opacity: 0.5;
    outline: none;

    &:hover {
      opacity: 0.65;
      background-color: #b9babb;
    }
    &:active,
    &:focus {
      opacity: 1;
      background-color: #fff;
      box-shadow: 0 0 0 1px #b9babb;
    }
  }

  .global-search {
    position: absolute;
    background-color: #f7f7f7;
    z-index: 9999;
    top: 100%;
    left: 0;

    padding: 0 0 8px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0 4px 4px 4px;
    .global-search-tabs {
      display: block;
      position: relative;
      overflow: hidden;
      width: 320px;
      max-height: calc(100vh - 48px);
      .nav-tabs {
        background-color: #ebebeb;
        border-bottom: 1px solid #b9babb;
        padding: 8px 8px 10px;
        .global-search-tab-title {
          position: relative;
          font-size: 12px;
          color: #404346;
          background-color: #e5e5e5;
          border: 1px solid #d5d5d5;
          border-radius: 4px;
          padding: 0px 10px;
          margin: 2px 4px;
          cursor: pointer;
          user-select: none;
          transition: all 0.4s ease;

          .counter {
            color: #858585;
            margin-left: 5px;
          }

          &.active {
            color: #5e5e5e;
            background-color: #fff;
            .counter {
              color: #2dabe2;
            }
          }
        }
      }

      .tab-content {
        display: block;
        position: relative;
        max-height: calc(100vh - 92px);
        overflow: auto;
        .search-results {
          list-style: none;
          display: block;
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;
            word-wrap: break-word;
            overflow: hidden;
          }
        }

        .no-result {
          padding: 10px;
          margin: 0;
        }
      }
    }
  }

  &.open {
    background-color: #ebebeb;
  }

  &.active {
    .icon {
      color: #2dabe2;
    }
  }
}
.mise-en-page-mobile {
  /* styles spécifiques au mobile ici */
  .fast-search {
    .search-input {
      height: 36px;
      border-radius: 48px;
      width: 30px;
      padding: 3px 3px 3px 33px;
      border-radius: 25px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: none;
      box-shadow: none;
      /* border: none; */
      opacity: 0.5;
      outline: none;
    }
    &.active {
      @keyframes move {
        from {
          left: 8px;
        }
        to {
          left: 0px;
        }
      }
      position: relative;
      animation-name: move;
      animation-duration: 0.5s;
      animation-direction: alternate;
      background-color: transparent;
      .search-input {
        width: 160px;
      }
    }
    .global-search {
      right: -64px;
      left: unset;
    }
  }
  @media screen and (max-width: 500px) {
    .fast-search {
      .global-search {
        right: -94px;
      }
    }
  }
  @media screen and (max-width: 280px) {
    .fast-search {
      &.active {
        .search-input {
          width: 83px;
        }
      }
      .global-search {
        right: -89px;
        .global-search-tabs {
          width: 264px;
        }
      }
    }
  }
}
</style>
