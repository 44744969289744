<template>
  <div
    class="app-container"
    :class="{
      'mise-en-page-bureau': estEcranBureau,
      'mise-en-page-mobile': !estEcranBureau
    }"
  >
    <div
      v-if="!onLine || showBackOnline"
      class="network-info"
      :class="{ online: showBackOnline, offline: !onLine }"
    >
      {{ showBackOnline ? 'la connexion a été rétablie' : 'Pas de connexion' }}
    </div>
    <Topbar />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import Topbar from '@/components/Topbar'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    Topbar
  },
  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
      estEcranBureau: true
    }
  },
  methods: {
    ...mapActions([
      'fetchfillPipelinesTable',
      'fetchfillStagesTable',
      'fetchListNotification'
    ]),
    onResize() {
      this.estEcranBureau = window.innerWidth >= 827 // ajustez le point de rupture selon vos besoins
    },
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true
        setTimeout(() => {
          this.showBackOnline = false
        }, 2000)
      }
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    this.fetchListNotification(this.currentUser.id)
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  main {
    display: block;
    position: relative;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    margin-top: 48px;
    height: calc(100% - 48px);
  }
  .network-info {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    color: #fff;
    text-align: center;
    padding: 5px 0;

    &.offline {
      background-color: #ee220c;
    }

    &.online {
      background-color: #28a745;
    }
  }
}
</style>
