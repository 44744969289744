<template>
  <div class="dropdown-container" @click="toggle($event)">
    <div class="dropdown" :class="{ active: active }">
      <slot name="header"></slot>
    </div>
    <div
      class="options"
      :class="{ active: active, 'router-link-active': utilsRouterLinkActive }"
    >
      <slot name="options"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false
    }
  },
  methods: {
    utilsRouterLinkActive: function() {
      return (
        this.$route.name === 'planning-amo' ||
        this.$route.name === 'planning-pose' ||
        this.$route.name === 'planning-visiteur-tech' ||
        this.$route.name === 'PacBallon' ||
        this.$route.name === 'Isolation'
      )
    },
    toggle(e) {
      this.$emit('notification')
      e.stopPropagation()
      this.active = !this.active
    }
  },
  mounted() {
    window.addEventListener(
      'click',
      e => {
        if (!this.$el.contains(e.target)) {
          this.active = false
        }
      },
      false
    )
  },
  beforeDestroy() {
    window.addEventListener('click', () => {})
  }
}
</script>

<style scoped lang="scss">
.dropdown-container {
  display: block;
  &.router-link-active {
    background-color: #dbdbdb;
    color: #343a40;
  }
  .dropdown {
    float: right;
    display: block;
    padding: 8px 14px 8px 12px;
    color: #fff;
    line-height: 16px;
    text-decoration: none;
    user-select: none;
    cursor: pointer;

    &.active {
      background-color: #fff;
      color: #474747;

      .caret {
        border-top: 6px solid #474747;
      }
    }
    img {
      float: left;
      width: 32px;
      height: 32px;
      overflow: hidden;
      border-radius: 26px;
    }
    .user-info {
      margin: 0 6px 0 6px;
      float: left;
      .name {
        display: block;
        white-space: nowrap;
      }

      .organisation {
        display: block;
        white-space: nowrap;
        font-size: 11px;
      }
    }

    .caret {
      float: left;
      margin-top: 13px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #f8f8f8;
    }
  }

  .options {
    display: none;
    width: 300px;
    height: auto;
    position: absolute;
    background-color: #fff;
    top: 100%;
    right: 0;
    padding: 10px 0;
    box-sizing: border-box;
    border-radius: 0px 0 4px 4px;
    box-shadow: 0 4px 8px rgba(38, 41, 44, 0.16);

    &.active {
      display: block;
    }

    a {
      padding: 10px;
      display: block;
      box-sizing: border-box;
      text-decoration: none;
      color: inherit;
      &:hover {
        background-color: rgba(49, 122, 226, 0.16);
      }
    }
  }
}
@media screen and (max-width: 827px) {
  .user-dropdown {
    .options {
      &.active {
        width: 100%;
        background-color: #404346;
        text-align: center;
        color: white;
      }
    }
  }
}
</style>
